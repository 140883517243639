// mobile warning
.mobile-warning {
  background-color: $primary-color;
  color: $white;
  font-family: "BebasNeue-Bold", sans-serif;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 11;
  p {
    @include vertical-center;
    color: $white;
    font-size: rem-calc(20);
    margin: 0 auto;
    max-width: rem-calc(320);
    left: 0;
    line-height: rem-calc(32);
    right: 0;
    text-align: center;
    width: 100%;
  }
}

// hero bg
.hero-bg {
  position: relative;
  margin-top: rem-calc(60)!important;
  background-image: url("/public/assets/images/bg-img.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include breakpoint(medium) {
    height: 70vh;
  }
  @include breakpoint(large) {
    height: 84vh;
  }
  .hero-content {
    height: 100%;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    .main-logo {
      @include vertical-center;
      left:0;
      margin: 0 auto;
      right: 0;
      width: 100%;
      @include breakpoint(small) {
        max-width: rem-calc(220);
      }
      @include breakpoint(medium) {
        max-width: rem-calc(600);
      }
      @include breakpoint(740) {
        max-width: rem-calc(780);
      }
      @include breakpoint(large) {
        background-color: initial;
        max-width: rem-calc(1100);
      }
    }

  }
  .register {
    @include vertical-center;
    right:0;
    width: 100%;
    @include breakpoint(small) {
      max-width: rem-calc(100);
    }
    @include breakpoint(medium) {
      max-width: rem-calc(120);
    }
    @include breakpoint(large) {
      max-width: rem-calc(180);
      top: calc(50% - 3rem);
    }
  }
  .bolt-left {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    @include breakpoint(small) {
      max-width: rem-calc(100);
    }
    @include breakpoint(medium) {
      max-width: rem-calc(120);
    }
    @include breakpoint(large) {
      max-width: rem-calc(160);
    }
  }
  .bolt-right {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    @include breakpoint(small) {
      max-width: rem-calc(100);
    }
    @include breakpoint(medium) {
      max-width: rem-calc(120);
    }
    @include breakpoint(large) {
      max-width: rem-calc(160);
    }
  }
  .speaker-left {
    bottom: 0;
    position: absolute;
    left: 0;
    width: 100%;
    @include breakpoint(small) {
      max-width: rem-calc(100);
    }
    @include breakpoint(medium) {
      max-width: rem-calc(130);
    }
    @include breakpoint(large) {
      max-width: rem-calc(200);
    }
  }
  .speaker-right {
    bottom: 0;
    position: absolute;
    right: 0;
    width: 100%;
    @include breakpoint(small) {
      max-width: rem-calc(100);
    }
    @include breakpoint(medium) {
      max-width: rem-calc(150);
    }
    @include breakpoint(large) {
      max-width: rem-calc(240);
    }
  }
}

// whats on
.whats-on {
  background-color: $primary-color;
  border-top: rem-calc(5) solid $primary-border;
  border-bottom: rem-calc(5) solid $primary-border;
  @include breakpoint(small) {
    padding: rem-calc(20) 0;
    text-align: center;
  }
  @include breakpoint(medium) {
    padding: rem-calc(60) 0;
    text-align: left;
  }
  ul {
    list-style: none;
    margin: 0;
    @include breakpoint(small) {
      margin: 0 0 rem-calc(8) 0;
    }
    @include breakpoint(medium) {
      margin: 0;
    }
    @include breakpoint(large) {
      margin: 0;
    }
    li {
      color: #12dd72;
      display: inline-block;
      font-family: "BebasNeue-Bold", sans-serif;
      font-weight: bold;
      letter-spacing: 2px;
      padding: rem-calc(10) rem-calc(50) rem-calc(10) rem-calc(10);
      @include breakpoint(small) {
        font-size: rem-calc(20);
      }
      @include breakpoint(medium) {
        font-size: rem-calc(60);
      }
      &:last-of-type {
        padding-right: rem-calc(10);
      }
    }
  }
  img {
    @include breakpoint(small) {
    }
    @include breakpoint(medium) {
      width: 100%;
    }
    @include breakpoint(large) {
      width: 100%;
    }
  }
}

// form
.register-form {
  padding-top: rem-calc(48);
  .sign-up {
    color: $secondary-color;
    font-family: "BebasNeue-Bold", sans-serif;
    font-size: rem-calc(70);
    font-weight: bold;
    letter-spacing: 2px;
    line-height: rem-calc(70);
    margin: rem-calc(40) 0;
    text-align: center;
  }
  .form {
    border: 1px solid $medium-gray;
    color: $black;
    font-size: rem-calc(18);
    line-height: rem-calc(18);
    padding: rem-calc(10);
    width: 100%;
    &.dob-a {
      margin-right: 4%;
      width: 20%;
    }
    &.dob-b {
      margin-right: 4%;
      width: 49%;
    }
    &.dob-c {
      width: 20%;
    }
    &.form--alt {
      background-image: url("/public/assets/images/chevron-down-small.png");
      background-position: calc(100% - 0.625rem) rem-calc(14);
      background-repeat: no-repeat;
      padding: rem-calc(10) rem-calc(40) rem-calc(10) rem-calc(10);
      text-indent: 1px;
      &:focus {
        background-image: url("/public/assets/images/chevron-up-small.png");
        background-position: calc(100% - 0.625rem) rem-calc(12);
      }
    }
    &.submit {
      background-color: #ffd600;
      border: none;
      color: $black;
      display: inline-block;
      padding: rem-calc(24) rem-calc(10);
      text-align: center;
      text-decoration: none;
      width: 100%;
    }
  }
  .dob-text {
    padding-bottom: 0;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
  }
  select::-ms-expand {
    display: none;
  }
  .checkbox-text {
    display: inline-block;
    font-size: rem-calc(20);
    line-height: rem-calc(64);
    margin: rem-calc(5) rem-calc(10) 0 0;
    vertical-align: top;
  }
  .checkbox {
    @include vertical-center;
    background-image: url("/public/assets/images/checkbox.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: inline-block;
    height: rem-calc(40);
    right: 0;
    width: rem-calc(40);
    &.checked {
      background-image: url("/public/assets/images/checkbox-checked.png");
    }
    .form {
      display: none;
    }
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#complete-popup,
#privacy-popup {
  background-color: $charcol;
  display: none;
  left: 0;
  margin: 0 auto;
  max-width: rem-calc(720);
  padding: rem-calc(48);
  position: fixed;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 1;
  &.active {
    display: block;
  }
  span {
    color: $white;
    display: block;
    font-size: rem-calc(60);
    line-height: rem-calc(60);
    margin-bottom: rem-calc(32);
    text-transform: uppercase;
  }
  button {
    background-color: #ffd600;
    color: $black;
    cursor: pointer;
    display: inline-block;
    font-family: $body-font-family;
    font-weight: bold;
    padding: rem-calc(16) rem-calc(32);
    text-transform: uppercase;
  }
}

#privacy-popup {
  span {
    font-family: $body-font-family;
    font-size: rem-calc(24);
    line-height: rem-calc(24);
    text-transform: none;
  }
}

#complete-popup-bg,
#privacy-popup-bg {
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  &.active {
    display: block;
  }
}

// 404
.page-404-bg {
  background-color: $primary-color;
  min-height: 100vh;
  text-align: center;
  .page-404-inner {
    @include vertical-center;
    left: 0;
    right: 0;
    h1 {
      color: #12dd72;
      display: inline-block;
      font-family: "BebasNeue-Bold", sans-serif;
      font-weight: bold;
      letter-spacing: 2px;
      padding: rem-calc(10);
    }
    p {
      color: $white;
      font-family: "BebasNeue-Bold", sans-serif;
      font-size: rem-calc(20);
      line-height: rem-calc(24);
      margin: rem-calc(40) 0;
      text-align: center;
    }
  }
}
