/* Header */
.header{
	position: relative;
	width: 100%;
	z-index: 10;

	.address-bar {
		background-color: $light-gray;
		left:0;
		position: fixed;
		top:0;		
		width:100%;		
		z-index: 1;
		.address-bar-icons {
			@include vertical-center;
			left: rem-calc(32);
			.icon-img {
				height: rem-calc(32);
				padding: 0 rem-calc(8);
			}
		}
		.address-bar-url {
			background-color: #e4e5e7;
			border-radius: rem-calc(6);
			display: inline-block;
			margin: rem-calc(10) 0 rem-calc(10) rem-calc(288);
			padding: rem-calc(8) rem-calc(10) rem-calc(8) rem-calc(40);
			position: relative;
			width: calc(100% - 22.375rem);
			
			.unlocked {
				@include vertical-center;
				cursor: pointer;
				left: rem-calc(10);
				width: rem-calc(20);
			}
			span {
				display: inline-block;
				font-family: Helvetica, sans-serif;
				font-size: rem-calc(16);
				line-height: rem-calc(16);
			}
			.icon-close {
				@include vertical-center;
				right: rem-calc(10);
				width: rem-calc(20);
			}
		}
		.cancel {
			color: #0069dd;
			display: inline-block;
			font-size: rem-calc(15);
			font-weight: bold;
			padding-left: rem-calc(5);
		}
	}

	.security-message {
		background-color: $charcol;
		border-radius: rem-calc(10);
		display: none;
		left: 8.33333%;
		max-width: rem-calc(480);
		padding: rem-calc(20);
		position: absolute;
		top: -0.5rem;
		width: 100%;
		z-index: 10;
		&.active {
			display: block;
		}
		.security-message-top {
			border-bottom: 1px solid $medium-gray;
			margin-bottom: rem-calc(20);
			h2 {
				color: $white;
				font-size: rem-calc(22);
				margin-bottom: rem-calc(20);
			}
			p {
				color: $white;
			}
		}
		&:before {
			content: '';
			display: block;
			border-bottom: rem-calc(10) solid $charcol;
			border-left: rem-calc(10) solid transparent;
			border-right: rem-calc(10) solid transparent;				
			height: 0;
			left:rem-calc(10);
			position: absolute;
			top: -0.625rem; 
			width: 0; 
		}
		.security-cookie {			
			p {
				background-image: url('/public/assets/images/icon-cookie.png');
            	background-position: center left;
				background-repeat: no-repeat;
				background-size: contain;
				color: $white;
				font-weight: bold;
				margin:0;
				padding-left: rem-calc(40);
				span {
					display: inline-block;
					font-weight: 400;
					padding-left: rem-calc(10);
				}
			}
		}
	}

	.timer {
		background-color: $white;
		border: rem-calc(10) solid $charcol;
		font-family: Helvetica, sans-serif;
		left:0;
		padding: rem-calc(20) rem-calc(30) rem-calc(48) rem-calc(30);
		position: fixed;
		top: rem-calc(60);
		.timer-text {
			font-size: rem-calc(25);
			font-weight: 100;
			line-height: rem-calc(25);
			max-width: rem-calc(92);
			text-transform: uppercase;
		}
		#timer-countdown {
			font-size: rem-calc(40);
			font-weight: 400;
			line-height: rem-calc(40);
			text-align: right;
		}
		.timer-warning {
			bottom: rem-calc(8);
			left: rem-calc(30);
			max-width: rem-calc(192);
			position: absolute;			
			p {
				color: $black;
				font-size: rem-calc(12);
				font-weight: bold;
				line-height: rem-calc(14);
				margin: rem-calc(12) 0 0 0;
			}
		}
	}

	#timer-popup {
		background-color: $charcol;		
		display: none;
		left:0;
		margin: 0 auto;
		max-width: rem-calc(720);
		padding: 3rem;
		position: fixed;
		right:0;
		text-align: center;		
		top: 50%;
		transform: translateY(-50%);
		width: 100%;		
		z-index: 1;
		&.active {
			display: block;
		}
		span {
			color: $white;
			display: block;
			font-size: rem-calc(60);
			line-height: rem-calc(60);
			margin-bottom: rem-calc(32);
			text-transform: uppercase;
		}
		button {
			background-color: #ffd600;
			color: $charcol;
			cursor: pointer;
			display: inline-block;
			font-weight: bold;
			padding: rem-calc(16) rem-calc(32);
			text-transform: uppercase;
		}
	}
	
	#timer-popup-bg {
		background-color: rgba(0,0,0,0.5);
		display: none;
		height:100%;
		left:0;
		position: fixed;
		top:0;
		width: 100%;
		&.active {
			display: block;
		}
	}
}
