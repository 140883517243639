@mixin padding-list($gap-size:10, $end:10) {

    .p-no{
      padding:0;
    }
  
    @for $i from 1 through 30 {
      $num: $i * 10;
      .p-t-b-#{$num} {
        @include breakpoint(small) {
          padding-top: rem-calc($num/2);
          padding-bottom: rem-calc($num/2);
        }
        @include breakpoint(large) {
          padding-top: rem-calc($num);
          padding-bottom: rem-calc($num);
        }
      }
    }
    @for $i from 1 through 30 {
      $num: $i * 10;
      .p-t-#{$num} {
        @include breakpoint(small) {
          padding-top: rem-calc($num/2);
        }
        @include breakpoint(large) {
          padding-top: rem-calc($num);
        }
      }
    }
    @for $i from 1 through 30 {
      $num: $i * 10;
      .p-b-#{$num} {
        @include breakpoint(small) {
          padding-bottom: rem-calc($num/2);
        }
        @include breakpoint(large) {
          padding-bottom: rem-calc($num);
        }
      }
    }    
}

@mixin margin-list($gap-size:10, $end:10) {

  @for $i from 1 through 30 {
    $num: $i * 10;
    .m-t-b-#{$num} {
      @include breakpoint(small) {
        margin-top: rem-calc($num/2);
        margin-bottom: rem-calc($num/2);
      }
      @include breakpoint(large) {
        margin-top: rem-calc($num);
        margin-bottom: rem-calc($num);
      }
    }
  }

  @for $i from 1 through 30 {
    $num: $i * 10;
    .m-t-#{$num} {
      @include breakpoint(small) {
        margin-top: rem-calc($num/2);
      }
      @include breakpoint(large) {
        margin-top: rem-calc($num);
      }
    }
  }

  @for $i from 1 through 30 {
    $num: $i * 10;
    .m-b-#{$num} {
      @include breakpoint(small) {
        margin-bottom: rem-calc($num/2);
      }
      @include breakpoint(large) {
        margin-bottom: rem-calc($num);
      }
    }
  }

  @for $i from 1 through 30 {
    $num: $i * 10;
    .m-r-#{$num} {
      @include breakpoint(small) {
        margin-right: rem-calc($num/2);
      }
      @include breakpoint(large) {
        margin-right: rem-calc($num);
      }
    }
  }

  @for $i from 1 through 15 {
    $num: $i * 10;
    .m-l-#{$num} {
      @include breakpoint(small) {
        margin-left: rem-calc($num/2);
      }
      @include breakpoint(large) {
        margin-left: rem-calc($num);
      }
    }
  }
}