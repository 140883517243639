// typography

h1,h2,h3,h4,h5,h6{
	font-weight: 500;
}

.light{
	font-weight: 300!important;
}
.medium{
	font-weight: 500!important;
}
.bold{
	font-weight: 700!important;
}

.underline{
	text-decoration: underline;
}

p, li{
    color: $dark-gray;
    @include breakpoint(small){
        font-size: 14px;
        line-height: 19px;
    }
    @include breakpoint(large){
        font-size: 16px;
        line-height: 26px;        
    }
	a{
		text-decoration: underline;
	}
}