footer{
    background-color: #373080;
    color: $white;
    font-family: "BebasNeue-Bold";
    font-size: rem-calc(24);
    line-height: rem-calc(24);
    margin-top: rem-calc(60);
    position: relative;
    @include breakpoint(small){
        padding: rem-calc(10) 0;
    }
    @include breakpoint(medium){
        padding: rem-calc(32) rem-calc(50);
    } 
    .privacy-link {
        @include vertical-center;
        border: 1px solid $white;
        color: $white;
        cursor: pointer;
        display: inline-block;
        font-family: Helvetica, Arial, sans-serif;
        font-size: rem-calc(32);
        left: 0;
        line-height: rem-calc(32);
        margin: 0 auto;
        padding: rem-calc(10);
        right: 0;
        text-align: center;
        width: rem-calc(240);
    }   
}